//Media queries   
$telefono: 375px;
$telefonoGrande: 414px;
$tablet: 768px;
$desktop: 1200px;
$desktopGrande: 1400px;

@mixin telefono {
    @media only screen and (min-width: #{$telefono}){
        @content;
    }   
}
@mixin telefonoGrande {
    @media only screen and (min-width: #{$telefonoGrande}){
        @content;
    }  
}
@mixin tablet  {
    @media only screen and (min-width: #{$tablet}){
        @content;
    }   
}

@mixin desktop {
    @media only screen and (min-width: #{$desktop}){
        @content;
    }   
}
@mixin desktopGrande {
    @media only screen and (min-width: #{$desktopGrande}){
        @content;
    }
    
}

//Fuentes
@font-face{
    font-family: Bowhouse;
    src: url(../styles/fonts/Bowhouse-Regular.otf);
}

@font-face{
    font-family: Generica;
    src: url(../styles/fonts/Generica.otf);
}

@font-face{
    font-family: Generica Bold;
    src: url(../styles/fonts/GenericaBold.otf);
}

$Bowhouse: Bowhouse;
$Generica: Generica;
$GenericaBold: Generica Bold;


//Corrector padding
html{
    box-sizing: border-box;
}
*,
*:after,
*:before{
    box-sizing: inherit;
}

*{
    margin: 0;
    padding: 0;
}

ul,li{
    list-style: none;
}

a{
    text-decoration: none;
}

.container__main{
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 1rem 0;
    

    @include desktop(){

        height: 100vh;
        background: url(./background/background2-03.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        padding: 7rem 0;
    }
    
}

.container{
    height: 600px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include desktop(){
        height: 500px;
        
    }

    
}

h2{
    text-align: center;
    flex: 100%;
    font-size: 32px;
    font-family: $GenericaBold;
    color: #133555;

    @include desktop(){
        font-size: 38px;
    }

    @include desktopGrande(){
        font-size: 38px;
    }
}

.wrap__info{
    flex:100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include desktopGrande(){
        margin-right: 2rem
    }
    
   
}

.container__info{
    flex: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
    margin-bottom: .5rem;

    @include desktop(){
        flex: 23% 0 0;
    }

    p{
        text-align: center;
        font-size: 18px;
        font-family: $Generica;
    
        @include desktop(){
            font-size: 20px;
        }
    
        @include desktopGrande(){
            font-size: 21px;
        }
    }
    

    .icon{
        font-size: 1.5rem;
        color: #133555;
        margin-right: .5rem; 
        
        
        @include desktop(){

        }
    }


}



.container__form{
    background-color: white;
    height: 425px;
    width: 660px;

    display: flex;
    flex-wrap: wrap;

    
}

.logo__wrap{
    flex: 100%;
    height: 100%;
    background-color: #133555;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding: 6.5rem 0;

    @include desktop(){
        flex: 50%;
    }

    .btn{
        width: 100%;
        display:  flex;
        justify-content: center;
    }

    .button__cataologo{
        display: block;
        background-color: white;
        
        cursor: pointer;
        width: 60%;
        height: 30px;
        border: none;
        border-radius: 1rem;
        outline: none;
        font-family: $Generica;
        font-weight: 600;
        font-size: 1rem;

        a{
            color: #133555;
        }
    }   
}

form{
    flex: 40%;
    height: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    

    @include desktop(){
        padding: 6rem 0;
        height: 100%;
        margin-top: 0;
    }

    input{
        width: 80%;
        border: none;
        border-bottom: 2px solid #133555;
        outline: none;
        margin-bottom: 1rem;
        font-size: 22px;
        font-family: $Bowhouse;

        @include desktop(){
            margin-bottom: 1.5rem;
        }
    }

    .wrap{
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;
        
        input{
            flex: 3%;
            margin-top: 1rem;
            margin-left: 0.5rem;

            @include desktop(){
                margin-left: 0;
            }
        }
        p{
            flex: 90%;
            text-align: center;
            font-family: $Generica;
            font-weight: 600;
            font-size: .9rem;

            a{
                color: #76946E;
            }
        }
    }

    .submit{
        display: block;
        background: rgb(19,53,85);
        background: linear-gradient(90deg, rgba(19,53,85,1) 27%, rgba(118,148,110,1) 92%);
        
        color: white;
        cursor: pointer;
        height: 30px;
        border: none;
        border-radius: 1rem;
        outline: none;
        width: 80%;
        font-family: $Generica;
        font-weight: 500;
        font-size: 1rem;

        margin-bottom: 2rem;

        @include desktop(){
            margin-bottom: 0;
        }

    }
}


.error{
    flex: 100% 0 0;
    display: flex;

    justify-content: center;
    align-items: center;
    height: 30px;
    margin-bottom: .5rem;

    @include desktop(){
        flex: 50%;
    }

    p{
        text-align: center;
        font-size: 16px;
        font-family: $Generica;
        font-weight: 700;
        color: #133555;
    }
}



//Swal

.swal2-title{
    font-family: $GenericaBold;
    color: #133555;
}

.swal2-html-container{
    font-family: $Bowhouse;
    font-size: 22px;
}

.swal2-styled.swal2-confirm{
    color: #fff;
    font-family: $Generica;
    border:none !important;
    outline: none !important;

}

.swal2-styled.swal2-confirm:before{
    color: #fff;
    font-family: $Generica;
    border:  none !important;
    outline:  none !important;
}

.swal2-styled.swal2-confirm:after{
    color: #fff;
    font-family: $Generica;
    border:  none !important;
    outline:  none !important;
}


